@import "helpers/vars"

.no-padding-horizontal
  padding-right: 0
  padding-left: 0

.fluid-image
  width: 100%

.btn
  &.btn-primary
    background: transparent
    border: 1px solid
    color: $brandBlue

    &:hover
      background: $brandBlue
      color: #FFFFFF

.clearfix
  zoom: 1

  &:after
  &:before
    content: ""
    display: table

  &:after
    clear: both

[data-tooltip]
  position: relative

  &:after
    content: attr(data-tooltip)
    position: absolute
    font-size: 9px
    text-transform: uppercase
    background: rgba(0, 0, 0, .4)
    color: #FFFFFF
    white-space: nowrap
    top: 0
    padding: 2px 5px
    left: 50%
    transform: translate(-50%, 10px)
    opacity: 0
    visibility: hidden
    transition: all .5s ease

  &:hover
    z-index: 5

    &:after
      opacity: 1
      visibility: visible
      transform: translate(-50%, -20px)

img
  &[align='left']
  &.img-left
    margin-right: 30px
    margin-bottom: 30px
    float: left

  &[align='right']
  &.img-right
    margin-left: 30px
    margin-bottom: 30px
    float: right

textarea
input:not([type='submit']):not([type='submit'])
  background: $boxGreyBackground
  border: 0
  border-radius: 5px
  padding: 20px
  font-style: italic
  font-family: 'Proxima'
  font-size: 14px
  color: $inputColor
  width: 100%

select
  border: 0
  background: $boxGreyBackground
  color: $inputColor

.alert
  > p
    margin: 0

.table
  font-size: 13px

  thead
    tr
      th
        background-color: #e8e9ea
        text-transform: uppercase
        font-size: 15px
        text-align: center
        border: 0
        border-right: 1px solid #dadada
        border-top: 1px solid #dadada

        &:first-of-type
          min-width: 100px

        &:last-child
          border-right: 1px solid #e8e9ea
  tr
    td
      border: 1px solid #dadada
      vertical-align: middle
      text-align: center

      &.product
        background-color: #f4f5f7

