.no-padding-horizontal {
  padding-right: 0;
  padding-left: 0;
}
.fluid-image {
  width: 100%;
}
.btn.btn-primary {
  background: transparent;
  border: 1px solid;
  color: #1b9ade;
}
.btn.btn-primary:hover {
  background: #1b9ade;
  color: #fff;
}
.clearfix {
  zoom: 1;
}
.clearfix:after,
.clearfix:before {
  content: "";
  display: table;
}
.clearfix:after {
  clear: both;
}
[data-tooltip] {
  position: relative;
}
[data-tooltip]:after {
  content: attr(data-tooltip);
  position: absolute;
  font-size: 9px;
  text-transform: uppercase;
  background: rgba(0,0,0,0.4);
  color: #fff;
  white-space: nowrap;
  top: 0;
  padding: 2px 5px;
  left: 50%;
  transform: translate(-50%, 10px);
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease;
}
[data-tooltip]:hover {
  z-index: 5;
}
[data-tooltip]:hover:after {
  opacity: 1;
  visibility: visible;
  transform: translate(-50%, -20px);
}
img[align='left'],
img.img-left {
  margin-right: 30px;
  margin-bottom: 30px;
  float: left;
}
img[align='right'],
img.img-right {
  margin-left: 30px;
  margin-bottom: 30px;
  float: right;
}
textarea,
input:not([type='submit']):not([type='submit']) {
  background: #f4f5f7;
  border: 0;
  border-radius: 5px;
  padding: 20px;
  font-style: italic;
  font-family: 'Proxima';
  font-size: 14px;
  color: #898b8c;
  width: 100%;
}
select {
  border: 0;
  background: #f4f5f7;
  color: #898b8c;
}
.alert > p {
  margin: 0;
}
.table {
  font-size: 13px;
}
.table thead tr th {
  background-color: #e8e9ea;
  text-transform: uppercase;
  font-size: 15px;
  text-align: center;
  border: 0;
  border-right: 1px solid #dadada;
  border-top: 1px solid #dadada;
}
.table thead tr th:first-of-type {
  min-width: 100px;
}
.table thead tr th:last-child {
  border-right: 1px solid #e8e9ea;
}
.table tr td {
  border: 1px solid #dadada;
  vertical-align: middle;
  text-align: center;
}
.table tr td.product {
  background-color: #f4f5f7;
}
