font-url(file)
  return '../../media/fonts/' + file

webfont(family, file, hack-chrome-windows = false, weight = 'normal')
  @font-face
    font-family family
    src url(font-url(file + '.eot'))
    src url(font-url(file + '.eot?#iefix')) format('embedded-opentype'),
        url(font-url(file + '.woff')) format('woff'),
        url(font-url(file + '.ttf')) format('truetype'),
        url(font-url(file + '.svg#'+ family)) format('svg')
    font-weight weight
    font-style normal

  if hack-chrome-windows
    @media screen and (-webkit-min-device-pixel-ratio:0)
      @font-face
        font-family family
        src url(font-url(file + '.svg#'+ family)) format('svg')


//Usage: webfont('Open Sans', 'open-sans-webfont', true, 'normal')
