@import "helpers/vars.styl"

.page

  h1
    font-size: 22px
    text-transform: uppercase
    font-family: 'Proxima Semi'
    letter-spacing: 2px
    margin: 0 0 30px 0
    position: relative

    small
      display: block
      font-family: 'Proxima'
      color: #8a8b8c
      font-size: 16px
      letter-spacing: .5px
      font-style: italic
      text-transform: none
      margin-top: 5px

    &:after
      content: ''
      height: 1px
      background: $brandBlue
      width: 30px
      display: flex
      margin-top: 20px

  h2
    font-size: 18px
    line-height: 24px
    color: #000000
    margin-bottom: 30px
    letter-spacing: .5px
    font-family: 'Proxima Semi'
    text-transform: uppercase

  h3
    font-family: 'Proxima Semi'
    color: $brandBlue
    font-size: 15px
    text-transform: none
    margin-bottom: 20px
    line-height: 24px
    letter-spacing: .5px

  h4
    text-transform: uppercase
    font-size: 16px
    color: #000000
    margin-bottom: 20px
    line-height: 24px
    letter-spacing: .5px

  a
    color: $linkColor
    text-decoration: none

    &:hover
      color: darken($linkColor, 60%)

  p
    line-height: 26px
    letter-spacing: .5px

  ul
    list-style: none
    padding-left: 10px

    &.list-unstyled
      li
        &:before
          content: none

    li
      margin-bottom: 5px

      &:before
        font-family: 'FontAwesome'
        content: "\f105"
        margin-right: 10px
        color: #8a8b8c
        font-size: 15px

  ol
    color: $brandBlue
    padding-left: 5px
    list-style: none
    counter-reset: item

    li
      color: #000000
      padding-left: 10px
      display: flex

      &:before
        content: counter(item) ". "
        counter-increment: item
        color: $brandBlue
        margin-right: 10px
        align-self: flex-end
