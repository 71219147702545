@import "helpers/vars"

body
  font-size: 15px
  font-family: 'Proxima'

.page
  min-height: 100vh

  .meta
    margin-top: 30px

    i
      margin-right: 5px
